import { AuthUtils } from 'app/core/auth/auth.utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { DatosService } from '@shared/service/app/datos.service';
import { IUsuario, User } from '@core/user/user.types';
import { NavigationService } from '@core/navigation/navigation.service';
import { Observable, of, switchMap, throwError, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private user: IUsuario;
    private _navigationMenu: any[] = [];
    private rutas: string[] = [];
    avisado = false;
    private subscription: Subscription; 

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _navigationService: NavigationService,
        private _datosService: DatosService
    ) { }

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for navigation menu
     */
    set navigationMenu(menu) {
        this._navigationMenu = menu;
    }

    get navigationMenu() {
        return this._navigationMenu;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------

    /**
     * Recuperar contraseña
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

        
    /**
     * Restablecer contraseña
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Iniciar sesión
     *
     * @param credentials
     */
    signIn(credentials): Observable<any> {

        return this._httpClient.post(`${environment.login.login}`, credentials).pipe(
            switchMap((response: any) => {
                if (response.success) {
                    this._datosService.setDatosUser(response.usuario);
                    this._datosService.setDatosRoles(response.roles); 
                    // Almacenar el token de acceso en el almacenamiento local
                    this.accessToken = response.token;
                    // Atencion sí cambia el estado tira 'undefined' y hay que volver a loguearse
                    
                    const usuario: User = {
                        id: response.usuario.codigo,
                        name: response.usuario.nick,
                        avatar: response.usuario.foto_app,
                        email: response.usuario.email,
                        status: response.usuario.activo
                    }
                    // Almacenar el usuario en el servicio de usuario
                    this._userService.user = usuario;
                    
                    // Establecer el indicador de autenticación en verdadero
                    this._authenticated = true;
                    
                    // Devolver un nuevo observable con la respuesta
                    return of(response);
                } else {
                    // Si no hay éxito, lanzar un error
                    return throwError("Inicio de sesión fallido");
                }
            })
        );
    }
    
    /**
     * Función que verifica las rutas que tiene asignadas el usuario logueado. Es utilizada por los guards.
     * @param path 
     * @returns boolean
     */
    verificarRutasPermitidas(path?): Observable<boolean> {
        // Comprobar si las rutas ya están cargadas
        if (this.rutas.length === 0) {
            // Las rutas no están cargadas, cárgalas
            return this.loadRoutes().pipe(
                switchMap(() => {
                    let existeRuta = this.rutas.find(r => r === path);
                    return of(!!existeRuta);
                })
            );
        } else {
            // Las rutas ya están cargadas
            let existeRuta = this.rutas.find(r => r === path);
            return of(!!existeRuta);
        }
    }

    /**
     * Cargar las rutas desde el menú de navegación
     */
    private loadRoutes(): Observable<void> {
        return new Observable<void>((observer) => {
            // Esperar a que se establezca el menú de navegación
            this.subscription = this._navigationService.navigation$.subscribe((navigation) => {
                if (navigation && navigation.default && navigation.default.length > 0) {
                    this.extractRoutesFromMenu(navigation.default);
                    observer.next();
                    observer.complete();
                    if (this.subscription) { 
                        this.subscription.unsubscribe();
                    }
                }
            });
        });
    }

    /**
     * Función para extraer rutas del menú de navegación
     * @param menu
     */
    private  extractRoutesFromMenu(menu): void {
        this.rutas = [];
        menu.forEach((item) => {
            this.tieneHijos(item.children);
        });
    }

    /**
     * Función recursiva que recorre los submenús.
     * @param hijos 
     */
    private tieneHijos(hijos): void {
        hijos.forEach(subItem => {
            if (subItem.link) {
                let ruta = subItem.link.replace('/', '');
                if (!this.rutas.includes(ruta)) {
                    this.rutas.push(ruta);
                }
            }
            if (subItem.children && subItem.children.length > 0) {
                this.tieneHijos(subItem.children);
            }
        });
    }

    /**
     * Cerrar sesión
     */
    signOut(): Observable<any> {
        // Eliminar el token de acceso del almacenamiento local
        localStorage.clear();
        // Establecer el indicador de autenticación en falso
        this._authenticated = false;
        // Devolver el observable
        return of(true);
    }

    renewToken(): Observable<any> {
        return this._httpClient.post<any>(environment.menu, null);
    }

    /**
     * Registrarse
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Desbloquear sesión
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Verificar el estado de autenticación
     */
    check(): Observable<boolean> {
        // Comprobar si el usuario ha iniciado sesión
        if (this._authenticated) {
            return of(true);
        }

        // Comprobar la disponibilidad del token de acceso
        if (!this.accessToken) {
            return of(false);
        }

        // Comprobar la fecha de vencimiento del token de acceso
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // Si el token de acceso existe y no ha caducado, iniciar sesión con él
        return of(true);
    }

    getDataUser() {
        return this.user;
    }
}
