import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, BehaviorSubject, of, firstValueFrom } from 'rxjs';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from './navigation.data';
import { IMenu } from '@shared/models/menu';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    navegacionMenu = [];
    inProcess = false;
    private _navigation: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>({
        compact: [],
        default: [],
        futuristic: [],
        horizontal: []
    });
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    // private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        // Inicializar la navegación con los menús predeterminados
        this._navigation.next({
            compact: this._compactNavigation,
            default: this.navegacionMenu,
            futuristic: this._futuristicNavigation,
            horizontal: this._horizontalNavigation,
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter para la navegación
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter para los datos del menú de navegación
     */
    set _navigationMenuData(menuData) {
        this.navegacionMenu = this.parsearMenuNavegacion(menuData);
        // Actualizar la navegación con los nuevos datos del menú
        this._navigation.next({
            compact: this._compactNavigation,
            default: this.navegacionMenu,
            futuristic: this._futuristicNavigation,
            horizontal: this._horizontalNavigation,
        });
    }

    /**
     * Getter para los datos del menú de navegación
     */
    get _navigationMenuData() {

        return this.navegacionMenu;
    }

    /**
     * Esta función se utiliza para parsear los datos del menú de navegación
     * que devuelve el servicio backend.
     * @param menu 
     * @returns 
     */
    parsearMenuNavegacion(menu: IMenu[]) {
        let menuData = [];
        let hijos = []
        menu.forEach(i => {
            if (i.hijos.length > 0) {
                hijos = this.tieneHijos(i.hijos);
            }

            let item = {
                id: i.item.descripcion.toLowerCase().replace(' ', ''),
                title: i.item.descripcion,
                type: i.hijos.length > 0 ? 'collapsable' : 'basic',
                link: i.hijos.length == 0 ? '/' : i.item.ruta,
                icon: i.item.icono ? i.item.icono : null,
                children: hijos
            }
            menuData.push(item);
        })
        return menuData;
    }

    /**
    * Función recursiva que recorre los submenús para luego pintarlos.
    * @param hijos 
    */
    tieneHijos(hijos) {
        var data = [];
        let children = [];

        hijos.forEach(subItem => {
            if (subItem.hijos.length > 0) {
                let h = this.tieneHijos(subItem.hijos);
                children.push(h);
            }

            let item = {
                id: subItem.item.descripcion.toLowerCase().replace(' ', ''),
                title: subItem.item.descripcion,
                type: subItem.hijos.length > 0 ? 'collapsable' : 'basic',
                link: subItem.item.ruta,
                icon: subItem.item.icono ? subItem.item.icono : null,
                children: children
            }

            data.push(item);
        });

        return data;
    }

    /**
     * Obtener los menús
     */
    async getMenus() {
        if (!this.inProcess) {
            this.getMenusX().then(res => {
            this.inProcess = res;
            });
        }
    }

    async getMenusX(): Promise<boolean> {
        this.inProcess = true;
        const res = await firstValueFrom(this._httpClient.post(`${environment.menu}`, null));
        this._navigationMenuData = res['result'];
        return true;
    }

    // }
    /**
     * Obtener todos los datos de navegación
     */
    get(): Observable<Navigation> {
        const navigationData = {
            compact: this._compactNavigation,
            default: this._navigationMenuData,
            futuristic: this._futuristicNavigation,
            horizontal: this._horizontalNavigation,
        }

        return of(navigationData);
    }
    
}
