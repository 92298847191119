import { Route } from '@angular/router';
import { initialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';
import { PermisoGuard } from '@core/auth/guards/permiso.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: 'home' }, //antes accedia a esta pagina por defecto si pasaba algo
    { path: '', pathMatch: 'full', redirectTo: 'seleccion-roles' },

    // Redirect 'denegado' path to '/acceso-denegado'
    { path: 'acceso-denegado', pathMatch: 'full', redirectTo: 'acceso-denegado' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'seleccion-roles' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('./modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('./modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('./modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('./modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('./modules/auth/sign-out/sign-out.routes') },
            { path: 'acceso-denegado', loadChildren: () => import('./modules/admin/acceso-denegado/acceso-denegado.routes') },
            // { path: 'example', loadChildren: () => import('./modules/admin/home/home.routes') },
            { path: 'seleccion-roles', loadChildren: () => import('./modules/seleccion-roles/seleccion-roles.module').then(m => m.SeleccionRolesModule), data: { title: 'Seleccion de Roles' } },
            { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule), data: { title: 'Home' } },
            {
                path: 'empresas',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/empresa/empresa.module').then(m => m.AdminsitracionEmpresaModule),data: { title: 'Empresas' } 
            }, 
            {
                path: 'usuarios',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/usuario/usuario.module').then(m => m.AdminsitracionUsuarioModule),data: { title: 'Usuarios' } 
            },
            {
                path: 'proyectos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/proyectos/proyectos/proyectos.module').then(m => m.ProyectosModule), data: {title: 'Proyectos'}
            },
            {
                path: 'eventos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/proyectos/eventos/eventos.module').then(m => m.EventosModule), data: { title: 'Eventos' }
            }
        ]
    }

];